'use client';

import GridItem from './Thread/GridItem';
import ListItem from './Thread/ListItem';
import React, { ReactElement, useEffect, useState } from 'react';
import chunk from 'lodash.chunk';
import { css, cx } from 'styled-system/css';
import { useCurrentUser } from '@hooks';
import { useMediaQuery } from 'react-responsive';
import { vstack } from 'styled-system/patterns';
import type { FavoriteThreadType } from '@/types/threads';
import type { FeedType } from '@/types/feed';
type Props = {
  favorites?: FavoriteThreadType[];
  feed: FeedType[];
};
const Threads = ({
  favorites = [],
  feed
}: Props): ReactElement => {
  const user = useCurrentUser();
  const isTabletOrMobile = useMediaQuery({
    query: '(max-width: 1024px)'
  });
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    setIsMobile(isTabletOrMobile);
  }, [isTabletOrMobile]);
  if (user?.settings && user.settings.feed === 'list') {
    return <>
      <div className={cx(styles.main, styles.mainList)}>
        <div className={styles.container}>
          {favorites.length ? <div className={styles.favoritesContainer}>
                  <h3 className={styles.header}>
                    <i className={cx(styles.icon, 'fas fa-star')} />
                    Favorites
                  </h3>
                  {favorites.map(thread => <ListItem key={thread.id} thread={thread as any} />)}
                </div> : null}
          <h3 className={styles.header}>
            <i className={cx(styles.icon, 'fa-newspaper')} />
            Recent Threads
          </h3>
          {feed.map(thread => <ListItem key={thread.id} thread={thread} />)}
        </div>
      </div>
    </>;
  }
  const regularThreads = feed.filter(thread => !thread.is_promoted);
  const promotedThreads = feed.filter(thread => thread.is_promoted);
  const sections = chunk(regularThreads, Math.ceil(regularThreads.length / promotedThreads.length));
  return <>
    <div className={styles.main}>
      <div className={styles.container}>
        {favorites.length ? <div className={styles.favoritesContainer}>
                <h3 className={styles.header}>
                  <i className={cx(styles.icon, 'fas fa-star')} />
                  Favorites
                </h3>
                <div className={styles.favorites}>
                  {favorites.map((thread: any) => <GridItem key={thread.id} thread={thread} />)}
                </div>
              </div> : null}
        {isMobile ? <>
                <h3 className={styles.header}>
                  <i className={cx(styles.icon, 'fa-sparkles')} />
                  Promoted Threads
                </h3>
                <div className={cx(styles.threads, styles.promoted)}>
                  {promotedThreads.map((thread: any) => <GridItem key={thread.id} thread={thread} />)}
                </div>
                <h3 className={styles.header}>
                  <i className={cx(styles.icon, 'fa-newspaper')} />
                  Recent Threads
                </h3>
                <div className={styles.threads}>
                  {regularThreads.map((thread: any) => <GridItem key={thread.id} thread={thread} />)}
                </div>
              </> : <>
                <div className={styles.header}>
                  <h3 className={styles.promoted}>
                    <i className={cx(styles.icon, 'fa-sparkles')} />
                    Promoted Threads
                  </h3>
                  <h3 className={styles.recent}>
                    <i className={cx(styles.icon, 'fa-newspaper')} />
                    Recent Threads
                  </h3>
                </div>
                {sections.map((threads: any[], index) => <div className={styles.section} key={index}>
                        <div className={styles.promoted}>
                          <GridItem thread={promotedThreads[index]} />
                        </div>
                        <div className={cx(styles.threads, styles.recent)}>
                          {threads.map((thread: any) => <GridItem key={thread.id} thread={thread} />)}
                        </div>
                      </div>)}
              </>}
      </div>
    </div>
  </>;
};
const styles = {
  main: cx(vstack({
    gap: 8
  }), css({
    pl: {
      base: 0,
      lg: 8
    },
    py: {
      base: 4,
      lg: 8
    },
    w: '100%'
  })),
  mainList: css({
    gap: '0 !important'
  }),
  container: css({
    w: '100%'
  }),
  header: css({
    display: {
      lg: 'flex'
    },
    fontWeight: 700,
    justifyContent: 'center',
    mb: 8,
    textAlign: 'center'
  }),
  headerList: css({
    borderBottom: '1px solid token(colors.foregroundAlt)',
    pb: 8
  }),
  icon: cx('fal', css({
    mr: 2
  })),
  section: css({
    display: 'flex',
    flexDirection: {
      base: 'column',
      lg: 'row'
    },
    gap: 8,
    mb: 8
  }),
  favoritesContainer: css({
    mb: 8
  }),
  favorites: css({
    display: {
      base: 'flex',
      lg: 'grid'
    },
    flexDirection: 'column',
    gap: 8,
    gridTemplateColumns: 'minmax(0, 50fr) minmax(0, 50fr)'
  }),
  promoted: css({
    flex: 1,
    mb: {
      base: 8,
      lg: 0
    }
  }),
  recent: css({
    flex: 1
  }),
  threads: vstack({
    gap: 8
  })
};
export default Threads;